.status-bar {
    display: flex;
    align-items: center;
    padding: 0 100px;
  }
  
  .status-bar-stage {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    background-color: white;
    border: 2px solid #ddd;
    color: #ddd; /* Default icon color */
  }
  
  .status-bar-completed {
    background-color: #6495ED;
    color: white;
    border-color: #6495ED;
  }
  
  .status-bar-current {
    border-color: #6495ED;
    color: #1d8cf8;
  }
  
  .status-bar-incomplete {
    color: black;
  }
  
  .status-bar-connector {
    height: 7px;
    background-color: #ddd;
    flex-grow: 1; /* The connector grows to fill available space */
    
  }
  
  .status-bar-connector-completed {
    background-color: #6495ED; /* Color for completed connectors */
  }
  
  
  /* Adjust icon styles based on your specific icons */
  .tim-icons {
    font-size: 3em;
    color: inherit; /* Ensure icons inherit color from .stage */
  }

  .status-bar-icon i{
    font-size: 30px;
  }
  
  .status-bar-label {
    color: white;
    position: absolute; /* Absolute positioning within the stage container */
    bottom: 72px; /* Position label below the stage icon */
    left: 50%;
    transform: translateX(-50%); /* Center the label */
    width: 145%; /* Set the width to match the container */
    text-align: center;
    color: white;
    font-size: 12px;
  }